
import React, { useRef, useState } from 'react';
import {Link } from "react-router-dom";
import "./Carrusel.css"
import {  FaAngleDoubleLeft, FaAngleDoubleRight, FaAngleLeft, FaAngleRight } from "react-icons/fa";


const delay = 4000;

function Carrusel({imagenes}) {
  const [index, setIndex] = useState(0);
  const [timerRunning, setTimerRunning] = useState(true);  
  const timeoutRef = useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }
  React.useEffect(() => {
    if(timerRunning){
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === imagenes.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );
    } else{
      resetTimeout();
    }

    return () => {
      resetTimeout();
    };
  }, [index]);

  const handleButtonClick = () => {
    setTimerRunning(false);
  };

  return (
    <div className="slideshow">
      <div className="slideshowSlider" style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}>
        {imagenes.map((fotografia, index) => (
          <div className="slide" key={index}>
            <Link to={fotografia.link}>
              <img
                  src={fotografia.imagen}
                  alt='Texto'
                  className="image-in-div"
              />
            </Link>
            <p>{fotografia.titulo}</p>
          </div>
        ))}
      </div>
     
         
      <div className="slideshowDots">
        <div className='slide-left'>
          <FaAngleDoubleLeft className='slideshowDot slideshowDot-double-left'
              onClick={() => {
                  setIndex(0);
                  handleButtonClick();
              }}
          />
        </div>
        <div className='slide-center'>
          <FaAngleLeft className='slideshowDot' 
             onClick={() => {
                  index === 0 ? setIndex(imagenes.length - 1) : setIndex(index-1);
              }} />
          {imagenes.map((_, idx) => (
            <div key={idx} className={`slideshowDot${index === idx ? " active" : ""}`}
              onClick={() => {
                  setIndex(idx);
                  handleButtonClick();
              }}>
              
            </div>
          ))}
          <FaAngleRight className='slideshowDot' 
                  onClick={() => {
                   index === imagenes.length - 1 ? setIndex(0) : setIndex(index+1);
              }} />
        </div>
        <div className='slide-right'>
          <FaAngleDoubleRight className='slideshowDot slideshowDot-double-right'
              onClick={() => {
                  setIndex(imagenes.length - 1);
                  handleButtonClick();
              }}
          />
        </div>
      </div>
    </div>
  );
}





export default Carrusel;


