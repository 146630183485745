import React, { useEffect, useState } from 'react';
import "./Galeria.css";
import Carrusel from '../components/Carrusel/Carrusel';
import { EVENT_CLOSE_MENU } from '../utils/eventos';
import { FaSpinner } from 'react-icons/fa';

const VisorCarrete = ({fichero}) => {
  const [jsonData, setJsonData] = useState (null)

  useEffect ( () => {

    const sendEventCloseMenu = () => {
      
      if ((window.innerWidth >= 300 && window.innerWidth <= 900) || window.innerHeight <= 500) {
         document.dispatchEvent(new CustomEvent(EVENT_CLOSE_MENU, { detail: { message: 'CLOSE' } }));
        
      }
    };

      const jsonFile=process.env.PUBLIC_URL + fichero;
      

      fetch (jsonFile)
      .then (response => response.json ())
      .then (data => {
           setJsonData (data);
           sendEventCloseMenu ();
      })
      .catch (error => {

      });

       // Limpieza (equivalente a componentWillUnmount en clases)
    return () => {
      // Código de limpieza aquí
    };
    
  }, [fichero]);

    return (
    <>
      {jsonData ? (
      <div>    
        <Carrusel imagenes={jsonData.fotografias}/>
      </div>
      ) : (
        // Mostrar un mensaje de carga o manejar de otra manera
        <div className="galeria">
          <div className="loader">
            <FaSpinner className="spin" />
            <p>Cargando datos..</p>
          </div>
        </div>
      )}
    
    </>
    );
}

export default VisorCarrete;