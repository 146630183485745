import React, { useEffect, useState } from 'react';
import "./Galeria.css";
import "../components/VisorFotografias/VisorFotografias"
import VisorFotografias from '../components/VisorFotografias/VisorFotografias';
import { EVENT_CLOSE_MENU } from '../utils/eventos';
import { FaSpinner } from 'react-icons/fa';

function GaleriaDrive({fichero}) {
  
  const [jsonData, setJsonData] = useState (null)
  const [images, setImagesArray] = useState([]);
  
  
  

  useEffect ( () => {

      const sendEventCloseMenu = () => {
        if ((window.innerWidth >= 300 && window.innerWidth <= 900) || window.innerHeight <= 500) {
           
          document.dispatchEvent(new CustomEvent(EVENT_CLOSE_MENU, { detail: { message: 'CLOSE' } }));
          
        }
      };
      
      const jsonFile=process.env.PUBLIC_URL + fichero;  
      fetch (jsonFile)
      .then (response => response.json ())
      .then (data => {
        const fotos = data.fotografias.map( (foto, index) => {
          const modifiedLink = foto.imagen;
          return{
            imagen: modifiedLink,
            datos: 'Foto'
          };
        });
        
        setJsonData (data);
        setImagesArray (fotos);
        sendEventCloseMenu ();
      })
      .catch (error => {
          
      }
      );
    return () => {
      // Código de limpieza 
       setImagesArray([]);
    };
    
  }, [fichero]);

  return (
      <>
      
      {jsonData ? (        
          <div className="galeria-container"> 
             <h3>{jsonData.titulo}</h3>   
             <VisorFotografias fotografias={images}/>
          </div>
       
      ) : (
        // Mostrar un mensaje de carga o manejar de otra manera
        <div className="galeria">
          <div className="loader">
            <FaSpinner className="spin" />
            <p>Cargando datos..</p>
          </div>
        </div>
      )}      
    </>
  );
}

export default GaleriaDrive;