import logo from './logo.svg';
import './App.css';
import './pages/Galeria.css'
import React, { useEffect, useState } from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Main from "./components/Main/Main";
import VisorCarrete from "./pages/VisorCarrete"
import GaleriaDrive from './pages/GaleriaDrive';
import AboutMe from './pages/AboutMe';

import { FaSpinner } from 'react-icons/fa';



function App() {
  const [jsonData, setJsonData] = useState (null)
  const [fileData, setFileData] = useState (null)

  
  
useEffect(() => {
  const jsonFile=process.env.PUBLIC_URL + '/data/carrusel.json';
  setFileData (jsonFile);
  fetch(jsonFile) 
    .then(response => response.json())
    .then(data => {
      setJsonData (data);
      //alert("La resolución de tu pantalla es: " + window.innerWidth + " x " + window.innerHeight)
    })
    .catch(error => {
      console.error('Error al cargar :', error);
    });
}, []); // Este efecto se ejecuta solo una vez al montar el componente

if (!jsonData) {
  return (
  <div className="galeria">
  <div className="loader">
    <FaSpinner className="spin" />
    <p>Cargando datos..</p>
  </div>
  </div>)
}

  return (
    <>
    <div className='contenedor-pagina'>
      <div className="app-container">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Main opciones={jsonData.fotografias}/>}>
              <Route index element={<VisorCarrete fichero={fileData}/>} />
            {jsonData.fotografias.map ((fotografia, index) => (
              <Route path={fotografia.link} element={<GaleriaDrive fichero={fotografia.fichero_datos} />} />
            ))} 
            <Route path="/aboutme" element={<AboutMe />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
      
    </div>
    <footer className="footer">
      <p>© Almudena Martínez Quintana 2024. Todos los derechos reservados. Las imágenes en esta galería están protegidas por derechos de autor. Queda prohibida su reproducción, distribución o uso sin permiso previo por escrito.</p>
     
  </footer>
  </>
  );
}

export default App;
