

import React, { useState, useEffect } from 'react';
import "./VisorFotografias.css"
import LargeImageModal from './LargeImageModal';


const VisorFotografias = ({fotografias}) => {

    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedIndexImage, setSelectedIndexImage] = useState(0);
    
    const [images, setImages] = useState([]);

    useEffect(() => {
        setImages(fotografias);
        return () => {
            setImages([]);
          };

      }, [fotografias]); 

    
    return (    
        <div className="mosaic-container">
            {images.map((fotografia, index) => (
                <div class="mosaic-item" key={index}>
                    
                    <div onContextMenu={(e) => {
                           e.preventDefault ();
                           
                        }
                    }>
                    
                        <img src={fotografia.imagen} alt='Imagen' onClick={() => {
                                                                                   setSelectedImage(fotografia.imagen);
                                                                                   setSelectedIndexImage(index);
                                                                                  }
                                                                        }
                        />
              
                    </div>
                </div>
            ))}

            {selectedImage && (
                <LargeImageModal 
                    imageSel={{index: selectedIndexImage }} 
                    images={images} 
                    onClose={() => setSelectedImage(null)} 
                    on
                />
                )}
        </div>
    );
    

};

export default VisorFotografias;