import React, { useState, useEffect } from 'react';

import MarkdownRender from '../MarkdownRender/MarkdownRender';
import './Warning.css';
import { FaSpinner } from 'react-icons/fa';
import '../../pages/Galeria.css';

function Warning({ onAccept }) {
    const [show, setShow] = useState(true);
    const [mdcontent, setContent] = useState("");

    const fichero='/data/legal.md';

    const readMarkdown = (fichero) => {
      fetch(fichero)
        .then((res) => res.text())
        .then((text) => {
            setContent(text);
        });
    };

  // Prevent scrolling when the modal is open
  useEffect(() => {
    readMarkdown (fichero);
    if (show) {
      document.body.style.overflow = 'hidden';
    } else {
      
      document.body.style.overflow = 'auto';
    }
  }, [show]);

  const handleClose = () => setShow(false);

  const handleAccept = () => {
    setShow(false);
    onAccept();
  };

  const preprocessMarkdown = (text) => {
    // Eliminar retornos de carro repetidos y dejar solo uno
    return text.replace(/`{1,}/g, 'AA');
  };


  if(!show) return null
  return (

    <div className='modal-overlay'>
      <div className='modal-content-w'>
        <h3>Bienvenida/o a una muestra de mi trabajo</h3>
        
        <div style={{textAlign: 'justify'}}>
           {mdcontent ? (
              <MarkdownRender content={preprocessMarkdown(mdcontent)}/>
           ):(
            <div className="galeria">
            <div className="loader">
              <FaSpinner className="spin" />
              <p>Un momento por favor</p>
            </div>
            </div>
           )}   
        </div>
        <div className='boton-w' onClick={handleClose}>Aceptar condiciones</div>
      </div>  
    </div>
  );
}

export default Warning;