
import React, { useEffect, useState } from 'react';
import MarkdownRender from '../components/MarkdownRender/MarkdownRender';
import "./Galeria.css"
import './AboutMe.css'
import { EVENT_CLOSE_MENU } from '../utils/eventos';
import { FaSpinner } from 'react-icons/fa';

function AboutMe() {
    
    const [md, setContent] = useState("");

    useEffect(() => {

        
        const sendEventCloseMenu = () => {
            if ((window.innerWidth >= 300 && window.innerWidth <= 900) || window.innerHeight <= 500) {
            
              document.dispatchEvent(new CustomEvent(EVENT_CLOSE_MENU, { detail: { message: 'CLOSE' } }));
             
            }
          };

      const fichero='/data/aboutme.md';
      fetch(fichero)
        .then((res) => res.text())
        .then((text) => {
            setContent(text);
            sendEventCloseMenu ();
        
        });
    }, []);
    return (
        
        <div className="galeria-container"> 
            <h3>Sobre mí</h3>
             
            <div className='markdown'>
                <div className='markdown-image'><img src='/images/sobre_mi.png' height='100%' width='100%' alt='foto'></img></div>
                
                {md ? (
                <div className='markdown-texto'>
                    <MarkdownRender content={md}/> 
                </div>
                ):(
                    <div className="loader">
                        <FaSpinner className="spin" />
                        <p>Cargando datos..</p>
                    </div>
                )}

            </div>
        </div>

    );
}

export default AboutMe;