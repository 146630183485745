import React from 'react';
import { MdOutlineMail } from "react-icons/md";
import './Titulo.css'

const Titulo = ({texto}) => {

   const subject = 'Solicitud de información';
   const body = 'Cuerpo del correo';

    const handleClick = () => {
        
        const mailtoLink = `mailto:${texto.email}?subject=${encodeURIComponent(subject)}`;
        window.location.href = mailtoLink;
        
       
    };

    return (
        <div className='titulo'>
            <div className='titulo-col-foto'>
                <div class="foto-circular">
                    <img src="images/perfil.jpg" alt="foto perfil"/>
                </div>
            </div>
            <div className='titulo-col-datos'>
                
                <p className='titulo-texto'>{texto.titulo}</p>
                <p className='subtitulo-texto'>{texto.subtitulo}</p>

                <div className='titulo-contenedor' onClick={handleClick}>
                    <MdOutlineMail size={20} style={{ marginRight: '10px' }} />
                    <p className='texto-email'>{texto.email}</p>
                </div>
            </div>
        </div>
    );
};

export default Titulo;