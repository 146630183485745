import React from 'react';
import './LargeImageModal.css';
import { LazyLoadImage } from "react-lazy-load-image-component";
import { FaRegTimesCircle } from "react-icons/fa";
import { useSwipeable } from 'react-swipeable';
import {  FaAngleLeft, FaAngleRight } from "react-icons/fa";


const LargeImageModal = ({ imageSel, images, onClose }) => {
  
    const size=window.innerWidth-80;
    const [modalWidth, setModalWidth] = React.useState(size);
    const [modalHeight, setModalHeight] = React.useState(size);
    const [url, setUrl] = React.useState(null);
    const RIGHT = 0;
    const LEFT  = 1;
    let [indexSel, setIndex] = React.useState (imageSel.index);

    const changeImage = (direction) => {
      if (direction === RIGHT) {
        indexSel = (indexSel < images.length-1 ? indexSel+1 : images.length-1);
      } else if (direction === LEFT) {
        indexSel = (indexSel > 0 ? indexSel-1 : 0);
      }
      setIndex (indexSel);
      setUrl(images[indexSel].imagen);
    }

    const setDimensions = (img) => {
      const { width, height } = img;
      const maxWidth    = window.innerWidth * 0.9;
      const maxHeight   = window.innerHeight * 0.9;
      const widthRatio  = maxWidth / width;
      const heightRatio = maxHeight / height;
      const ratio       = Math.min(widthRatio, heightRatio);
      setModalWidth (width * ratio);
      setModalHeight (height * ratio);
    };

    const handleKeyDown = (event) => {
      if (event.key === 'ArrowRight') {
         changeImage (RIGHT);  
      } else if (event.key === 'ArrowLeft') {
         changeImage(LEFT);
      }
    };

    const handlers = useSwipeable({
      onSwipedLeft: () => {
        changeImage(RIGHT);
    
      },
      onSwipedRight: () => {
        changeImage (LEFT);
      },
      preventDefaultTouchmoveEvent: true,
      trackMouse: true
    });

  React.useEffect(() => {
    const img = new Image();

    img.src=images[indexSel].imagen;
    setUrl(images[indexSel].imagen);

    document.addEventListener('keydown', handleKeyDown);      
    img.addEventListener('load', setDimensions (img));

    return () => {
      img.removeEventListener('load', setDimensions(img));
      document.removeEventListener('keydown', handleKeyDown);
    };

  }, [images[indexSel]]);
    
  return (
    <div {...handlers} className="large-image-modal" >
       <span className="close" onClick={onClose}>
          <FaRegTimesCircle size={40}/>
        </span>
        
          <div className="modal-content" style={{ width: `${modalWidth}px`, height: `${modalHeight}px` }}>      
            <div>
              <LazyLoadImage onContextMenu={(e) => {
                              e.preventDefault ();
                            } 
                    } src={url} alt='Imagen' effect='blur' once />
            </div>
          </div>
          <div className="foot-modal">
          <FaAngleLeft className='foot-modal-izq'
              onClick={() => {
                changeImage(LEFT);
              }}
          />
          <div>{indexSel+1}/{images.length}</div>
          <FaAngleRight className='foot-modal-der'
              onClick={() => {
                changeImage(RIGHT);
              }}
          />
          </div>
    </div>
    
  );
};

export default LargeImageModal;