import React from 'react';
import ReactMarkdown from "react-markdown";

const MarkdownRender = ({content='## Contenido'}) => {
    return (
      <div className='markdown-container'>
        {/*<ReactMarkdown children={content} />*/}

<ReactMarkdown >{content}</ReactMarkdown>
      </div>
    );
};

export default MarkdownRender;