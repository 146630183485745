import React, { useState,useEffect, useContext } from 'react';
import {Outlet, Link } from "react-router-dom";
import { FaInstagram, FaLinkedin, FaTiktok, FaAlignJustify } from 'react-icons/fa';
import "../../App.css"
import Titulo from "../Titulo/Titulo.js"
import "./Main.css"
import Warning from '../Warning/Warning.js';
import { EVENT_CLOSE_MENU } from '../../utils/eventos';


const Main = ({opciones}) => {
  const [panelVisible, setPanelVisible] = useState(true);
  const [buttonVisible, setButtonVisible] = useState(false);
  

  const texto = {
      titulo:"Almudena Martínez Quintana",
      subtitulo:"Fotografía profesional",
      email:"fotoalmu5@gmail.com"
    };

    const handleAccept = () => {
      // Handle the acceptance of the conditions here
      
    };

    const togglePanelVisibility = () => {
      setPanelVisible(!panelVisible);
    };

    useEffect(() => {
      // Función para manejar la visibilidad del botón basado en la altura de la pantalla
      const handleButtonVisibility = () => {
        if ((window.innerWidth >= 300 && window.innerWidth <= 900) || window.innerHeight <= 500) {
          setButtonVisible(true);
          setPanelVisible(false);
        } else {
          setButtonVisible(false);
          setPanelVisible(true);
        }
      };

      const handleEventClose = (event) => {
      
        handleButtonVisibility ();
      };
  
  
      // Ejecuta la función para manejar la visibilidad del botón cuando se monta el componente
      handleButtonVisibility();
      // Agrega un listener para manejar cambios en la altura de la pantalla
      window.addEventListener('resize', handleButtonVisibility);
      document.addEventListener(EVENT_CLOSE_MENU, handleEventClose);
      
      return () => {
        // Limpia el listener cuando el componente se desmonta para evitar fugas de memoria
       
        window.removeEventListener('resize', handleButtonVisibility);
        document.removeEventListener(EVENT_CLOSE_MENU, handleEventClose);
      };
    }, []);

    return (
      <>
        <Warning onAccept={handleAccept} />
        <aside className="left-panel app-container-navbar">
          <div className='up-panel-navbar'>
            <Titulo texto={texto} />
          </div>

          
          {buttonVisible && (
            <div className='boton-menu' onClick={togglePanelVisibility}>
              <FaAlignJustify size={30} />
              {!panelVisible ? <p>Ver opciones</p> : <p>Ocultar opciones</p>}
            </div>
          )}
          {panelVisible && (
            <div className='down-panel-navbar'>
              <ul className='lista-galerias-ul'>
                  <li className='lista-galerias-li separador-top' key="100">
                      <div>
                        <Link to="/" className="linkPersonalizado">Destacados</Link>
                      </div>
                  </li>
                  
                  {opciones.map ((galeria, index)  => (
                      <li className='lista-galerias-li' key={index}>
                          <div>
                            <Link to={galeria.link} className="linkPersonalizado">{galeria.titulo}</Link>
                          </div>
                      </li>    
                  ))}

                  <li className='lista-galerias-li separador-top' key="99">
                      <div>
                        <Link to="/aboutme" className="linkPersonalizado">Sobre mí</Link>
                      </div>
                  </li>
              </ul>
            </div >)
          }
         
          <div className='social-nets'>
            <h3>Sígueme en redes sociales:</h3>
            <div>
              <a href="https://www.instagram.com/fotoalmu5/?igsh=ZmlxenJvZGJxcTJj" target="_blank" rel="noopener noreferrer">
                <FaInstagram size={30} style={{ marginRight: '10px' }} />
              </a>
              <a href="https://www.linkedin.com/in/almudena-mart%C3%ADnez-quintana-83a33527a/?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app" target="_blank" rel="noopener noreferrer">
                <FaLinkedin size={30} />
              </a>
              <a href="https://www.tiktok.com/@fotoalmu5?_t=8iv3fvPA5Td&_r=1" target="_blank" rel="noopener noreferrer">
                <FaTiktok size={30} />
              </a>
            </div>
          </div>
      </aside>
      <main className="right-panel">
           <Outlet/>
      </main>
      
      </>
    );
};

export default Main;
